import React, { useState, useEffect, useRef } from "react";
import "./ReservationStyles.scss";
import SideBar from "../../../components/ReservationComponents/sideBar/SideBar";
import TopBar from "../../../components/ReservationComponents/topBar/TopBar";
import more from "../../../assets/more.png";

import { useNavigate } from "react-router-dom";
import Drawer from "../../../components/ReservationComponents/drawer/Drawer";
import Button from "../../../components/button/Button";
import deleteIc from "../../../assets/Delete.svg";
import editIc from "../../../assets/edit.svg";
import GroupingDropdown from "../../../components/groupingDropDown/GroupingDropdown";
import TableGroupingModal from "../../../components/ReservationComponents/tableGroupingModal/TableGroupingModal";
import {
  getFloorManagementDetails,
  getOrganizationDetails,
} from "../../../../infra/apis/bookings/requests/Booking";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllFloorDetails,
  setIslocationSwitching,
  setOrganizationalDetails,
} from "../../../../application/reducer/bookingSlice";
import { RootState } from "../../../../application/store";
import {
  deleteTableMerging,
  getAllTableMerging,
  handleAutoMerging,
  updateAutoMerging,
} from "../../../../infra/apis/bookings/requests/TableMerging";
import SuccessModal from "../../../components/ReservationComponents/errorModal/SuccessModal";
import DeleteModal from "../../../components/ReservationComponents/errorModal/DeleteModal";

const TableGrouping: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(
    localStorage.getItem("drawerOpen")
      ? JSON.parse(localStorage.getItem("drawerOpen") || "false")
      : false
  );
  const [width, setWidth] = useState(window.innerWidth - 200);
  const [checked, setChecked] = useState<boolean>(true);
  const [selectedAssets, setSelectedAssets] = useState<any>([]);
  const [selectedMergingAssets, setSelectedMergingAssets] = useState<any>([]);

  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGrouping, setSelectedGrouping] = useState<any>("");
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<any>({});
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false);
  const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);
  const [screenWidth, setScreenWidth] = useState<any>(992);
  const [checkedOptimization, setCheckedOptimization] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const allFloorDetails = useSelector(
    (state: RootState) => state.booking.allFloorDetails
  );
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const islocationSwitching = useSelector(
    (state: RootState) => state.booking.islocationSwitching
  );
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    localStorage.removeItem("selectedDate");
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails === "" || userDetails === null) {
      navigate("/");
    } else {
      setChecked(!organizationDetails.AllowAutoMerge);
      let optimization =
        organizationDetails.GeneralSettings.TableOptimization.allow;
      setCheckedOptimization(optimization);
      getData();
    }
  }, [locationId || "initial"]);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth - 200);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setIsError(false);
  }, [selectedMergingAssets]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenPopupIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const getData = async () => {
    setLoading(true);
    getFloorData();
    await getAllTableMerging().then((response: any) => {
      setData(response);
      setLoading(false);
      dispatch(setIslocationSwitching(false));
    });
  };
  const getFloorData = async () => {
    await getFloorManagementDetails().then((response: any) => {
      let array = response.flatMap((res: any) => res.Tables);
      setSelectedMergingAssets(array);
      dispatch(setAllFloorDetails(response));
    });
  };
  const handleDelete = async (id: any) => {
    await deleteTableMerging(id)
      .then((response) => {
        if (response) {
          setDeleteLoading(false);
          setSelectedGrouping("");
          setIsDeleteOpen(false);
          setIsDeleteSuccess(true);
        } else {
          setDeleteLoading(false);
        }
      })
      .catch(() => {
        setDeleteLoading(false);
      });
  };
  const autoMerging = async (status: boolean, optimizationStatus: boolean) => {
    await handleAutoMerging(status, optimizationStatus).then(
      (locationId: any) => {
        getOrganizationDetails(locationId).then((orgnizationDetails) => {
          dispatch(setOrganizationalDetails(orgnizationDetails));
        });
        setIsDeleteOpen(false);
      }
    );
  };
  const createNewTableGrouping = () => {
    const transformedData = selectedMergingAssets.map((item: any) => ({
      Id: item.ID,
      Enable: item.IsMergeable,
    }));
    let isValid = transformedData.filter((val: any) => val.Enable);
    if (isValid.length > 1) {
      setUpdateLoading(true);
      updateAutoMerging(transformedData).then((succes) => {
        if (succes) {
          getFloorData();
          setUpdateLoading(false);
          setIsSuccess(true);
        }
      });
    } else {
      setIsError(true);
    }
  };
  const morePopup = (value: any) => {
    if (openPopupIndex !== value.TableMergeID) return null;
    return (
      <div className='dropdown-content' ref={dropdownRef}>
        <div
          className='item'
          onClick={() => {
            setSelectedGrouping(value);
            setIsDeleteOpen(true);
          }}
        >
          <img alt='' src={deleteIc} />
          Delete
        </div>

        <div
          className='item'
          onClick={() => {
            setUpdateData(value);
            setIsEditOpen(true);
          }}
        >
          <img alt='' src={editIc} />
          Edit
        </div>
      </div>
    );
  };
  return (
    <div className='Reservation'>
      {islocationSwitching ? (
        <div className='empty'>
          <div className='loader' />
        </div>
      ) : screenWidth > 900 ? (
        <>
          {isDrawerOpen ? (
            <Drawer
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selectedMenu={"TABLE GROUPING"}
            />
          ) : (
            <SideBar
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selected={"TABLE GROUPING"}
            />
          )}
          <div
            className='reservation-content'
            style={{ marginLeft: isDrawerOpen ? "16.25rem" : "5rem" }}
          >
            <TopBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title='Table Grouping'
              isOpenDrawer={isDrawerOpen}
            />
            {loading ? (
              <div className='loader' />
            ) : (
              <div className='grouping-view'>
                <div
                  className='grouping-fixed-view'
                  style={{ width: isDrawerOpen ? width : "" }}
                >
                  <div className='right-conts'>
                    <div className='switch-containers'>
                      Manual Grouping
                      <label className='switch'>
                        <input
                          type='checkbox'
                          checked={checked}
                          onClick={() => {
                            autoMerging(
                              !organizationDetails.AllowAutoMerge,
                              checkedOptimization
                            );
                            setChecked(!checked);
                          }}
                        />
                        <span className='slider round'></span>
                      </label>
                    </div>
                    {checked && (
                      <div className='switch-containers'>
                        Table Optimization
                        <label className='switch'>
                          <input
                            type='checkbox'
                            checked={checkedOptimization}
                            onClick={() => {
                              autoMerging(
                                organizationDetails.AllowAutoMerge,
                                !checkedOptimization
                              );
                              setCheckedOptimization(!checkedOptimization);
                            }}
                          />
                          <span className='slider round'></span>
                        </label>
                      </div>
                    )}
                  </div>

                  <div className='btn-container'>
                    {checked && (
                      <Button
                        auth
                        text='Add Grouping'
                        height='34px'
                        padding='0.5rem 1rem'
                        fontWeight={500}
                        icon
                        onClick={() => {
                          setIsAddOpen(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                {checked ? (
                  <div className='table-view'>
                    <div className='table-grouping'>
                      <div className='grouping-header '>Table Grouping</div>
                      <div className='grouping-table'>
                        {data.length > 0 ? (
                          <table id='bookings'>
                            <tr>
                              <th>Group Name</th>
                              <th>Assets</th> <th>Min. Covers</th>{" "}
                              <th>Max. Covers</th>
                              <th></th>
                            </tr>
                            {data.map((value: any) => {
                              return (
                                <tr style={{ cursor: "pointer" }}>
                                  <td>{value.GroupName}</td>
                                  <td>
                                    <div className='tables'>
                                      {value.MergedTables.map((table: any) => {
                                        return <div>{table.TableName}</div>;
                                      })}
                                    </div>
                                  </td>
                                  <td>{value.Cover.Minimum}</td>
                                  <td> {value.Cover.Maximum}</td>
                                  <td>
                                    <div className='more-icon'>
                                      <img
                                        alt=''
                                        src={more}
                                        onClick={() => {
                                          setOpenPopupIndex(
                                            openPopupIndex ===
                                              value.TableMergeID
                                              ? null
                                              : value.TableMergeID
                                          );
                                        }}
                                      />
                                      {morePopup(value)}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        ) : (
                          <div className='empty-container'>
                            No table grouping available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='table-view'>
                    <div className='create-table '>
                      <div className='grouping-header '>
                        Create a new table grouping
                      </div>

                      <div className='bottom-cont'>
                        {allFloorDetails.length > 0 ? (
                          <>
                            <div className='sub-header'>
                              Select Table Combination
                            </div>
                            <div className='floors'>
                              {[...allFloorDetails]
                                .sort((a, b) => a.Priority - b.Priority)
                                .map((value: any) => {
                                  return (
                                    <GroupingDropdown
                                      floor={value}
                                      selected={selectedMergingAssets}
                                      setSelected={setSelectedMergingAssets}
                                    />
                                  );
                                })}
                            </div>
                            {isError && (
                              <span className='error'>
                                *please select two or more tables
                              </span>
                            )}
                            <div className='btn-cont'>
                              <Button
                                auth
                                text='Update'
                                height='40px'
                                padding='0.5rem 1rem'
                                fontWeight={500}
                                width='90px'
                                onClick={createNewTableGrouping}
                                loading={updateLoading}
                              />
                            </div>
                          </>
                        ) : (
                          <div className='empty-container'>
                            No floor data available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {isAddOpen && (
            <TableGroupingModal
              onClose={() => {
                setSelectedAssets([]);
                setIsAddOpen(false);
              }}
              isOpen={isAddOpen}
              tableData={allFloorDetails}
              selected={selectedAssets}
              setSelected={setSelectedAssets}
              isEdit={false}
              setSuccess={setIsSuccess}
              data={data}
            />
          )}
          {isEditOpen && (
            <TableGroupingModal
              onClose={() => {
                setSelectedAssets([]);
                setIsEditOpen(false);
              }}
              isOpen={isEditOpen}
              tableData={allFloorDetails}
              selected={selectedAssets}
              setSelected={setSelectedAssets}
              isEdit={true}
              updateData={updateData}
              setUpdateSuccess={setIsUpdateSuccess}
              data={data}
            />
          )}
          <DeleteModal
            isOpen={isDeleteOpen}
            onClose={() => {
              setSelectedGrouping("");
              setIsDeleteOpen(false);
            }}
            onDelete={() => {
              setDeleteLoading(true);
              handleDelete(selectedGrouping.TableMergeID);
            }}
            isLoading={deleteLoading}
            content='Are you sure that you want to delete this table grouping? '
          />
          <SuccessModal
            isOpen={isSuccess}
            onClose={() => {
              window.location.reload();
              setIsSuccess(false);
            }}
            onOk={() => {
              window.location.reload();
              setIsSuccess(false);
            }}
            header='Successful!'
            content=' Successfully created new table grouping'
          />
          <SuccessModal
            isOpen={isUpdateSuccess}
            onClose={() => {
              window.location.reload();

              setIsUpdateSuccess(false);
            }}
            onOk={() => {
              window.location.reload();

              setIsUpdateSuccess(false);
              window.location.reload();
            }}
            header='Successfully Updated!'
            content='Successfully updated table grouping'
          />
          <SuccessModal
            isOpen={isDeleteSuccess}
            onClose={() => {
              window.location.reload();
              setIsDeleteSuccess(false);
            }}
            onOk={() => {
              window.location.reload();
              setIsDeleteSuccess(false);
            }}
            header='Successful Deleted!'
            content=' Successfully deleted the table grouping'
          />
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
    </div>
  );
};
export default TableGrouping;
