import React, { useEffect, useState } from "react";
import "./BlockingHourModalStyle.scss";
import img from "../../../../assets/close-circle.svg";
import tick from "../../../../assets/checkmark.png";
import moment from "moment";
import Button from "../../../button/Button";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../application/store";
import { setIsBlockedOpen } from "../../../../../application/reducer/bookingSlice";
import {
  blockReservations,
  deleteBlockedReservations,
  getBlockedReservations,
} from "../../../../../infra/apis/bookings/requests/Booking";
import DatePickerInput from "../../datePicker/DatePickerInput";
import Alert from "../../../../pages/auth/reservation/tableDetails/Alert";
interface BlockingHourModalProps {
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const BlockingHourModal: React.FC<BlockingHourModalProps> = ({
  setIsSuccess,
}) => {
  let sampleDate = localStorage.getItem("selectedDate")
    ? JSON.parse(localStorage.getItem("selectedDate") || "")
    : new Date().toDateString();
  const [date, setDate] = useState<string>(
    localStorage.getItem("selectedDate")
      ? moment(sampleDate) < moment()
        ? new Date().toDateString()
        : sampleDate
      : new Date().toDateString()
  );
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const dispatch = useDispatch();

  const [isBlocked, setIsBlocked] = useState(false);
  const [isBlockedOnline, setIsBlockedOnline] = useState(false);
  const [isBlockedInhouse, setIsBlockedInhouse] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<string>("");
  const [alert, setAlert] = useState<string>("");
  const [blockedData, setBlockedData] = useState<any>([]);

  const [errors, setErrors] = useState({
    timeExceeds: "",
  });
  const roundToNextQuarterHour = (time: moment.Moment): moment.Moment => {
    const minutes = time.minutes();
    const nextQuarterHour = Math.ceil(minutes / 15) * 15;

    if (nextQuarterHour === 60) {
      return time.add(1, "hour").startOf("hour").seconds(0);
    }

    return time.minutes(nextQuarterHour).seconds(0);
  };
  const isBlockedDateMatch = (newBlock: any) => {
    return blockedData.some((block: any) => {
      const issues: string[] = [];
      let errorMessage = "";
      const isSameOrganizationAndLocation =
        block.OrganizationID === newBlock.OrganizationID &&
        block.CloudLocationID === newBlock.CloudLocationID;

      const isSameBlockDate =
        new Date(block.BlockDate).toISOString().split("T")[0] ===
        new Date(newBlock.BlockDate).toISOString().split("T")[0];
      if (isSameBlockDate) {
        issues.push(
          `date matches with the existing block data (${
            new Date(block.BlockDate).toISOString().split("T")[0]
          })`
        );
      }
      const existingStart = block.BlockTime.Start;
      const existingEnd = block.BlockTime.End;
      const newStart = newBlock.BlockTime.Start;
      const newEnd = newBlock.BlockTime.End;

      const isTimeOverlap =
        (newStart >= existingStart && newStart <= existingEnd) ||
        (newEnd >= existingStart && newEnd <= existingEnd) ||
        (existingStart >= newStart && existingStart <= newEnd);
      if (isTimeOverlap) {
        issues.push("overlaps with the block time");
      }
      const isOnlineMatch =
        block.Configuration.Online === true &&
        newBlock.Configuration.Online === true;
      const isMenusMatch =
        block.Configuration.Menus === true &&
        newBlock.Configuration.Menus === true;
      const isInhouseMatch =
        block.Configuration.InHouse === true &&
        newBlock.Configuration.InHouse === true;
      if (isOnlineMatch || isMenusMatch || isInhouseMatch) {
        const configurations = [
          isOnlineMatch ? "Online" : "",
          isInhouseMatch ? "InHouse" : "",
          isMenusMatch ? "Menus" : "",
        ]
          .filter(Boolean)
          .join(", ");
        issues.push(`and has matching configurations (${configurations})`);
      }

      errorMessage = `${issues.join(", ")}`;
      let invalid =
        isSameOrganizationAndLocation &&
        isSameBlockDate &&
        isTimeOverlap &&
        (isOnlineMatch || isMenusMatch || isInhouseMatch);
      if (invalid) {
        setAlert(errorMessage);
      } else {
        setAlert("");
      }
      return invalid;
    });
  };
  const handleRoundTime = () => {
    const time = moment();
    const rounded = roundToNextQuarterHour(time);
    return !moment(date).isSame(moment(), "day")
      ? "00:00"
      : rounded.format("HH:mm");
  };
  const [startTime, setStartTime] = useState<string>(handleRoundTime());
  const [endTime, setEndTime] = useState<string>("23:45");

  useEffect(() => {
    setAlert("");
    getBlockedRes();
    setStartTime(handleRoundTime());
    setEndTime("23:45");
  }, [date || "initial"]);
  const getBlockedRes = () => {
    setDataLoading(true);
    getBlockedReservations(locationId, moment(date).format("YYYY-MM-DD"))
      .then((res) => {
        if (res) {
          setBlockedData(res);
          setDataLoading(false);
        } else {
          setDataLoading(false);
        }
      })
      .catch(() => {
        setDataLoading(false);
      });
  };

  const generateTimeSlots = (startTime: string, endTime: string): string[] => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const timeSlots: string[] = [];
    while (start <= end) {
      const formattedTime = start.toTimeString().slice(0, 5);
      timeSlots.push(formattedTime);
      start.setMinutes(start.getMinutes() + 15);
    }
    return timeSlots;
  };
  const generateNewStart = () => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    start.setMinutes(start.getMinutes() + 15);
    return moment(start).format("HH:mm");
  };
  const slots = generateTimeSlots(handleRoundTime(), "23:30");
  const endTimeSlots = generateTimeSlots(generateNewStart(), "23:45");

  const handleStartChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAlert("");
    handleChange("timeExceeds");
    setStartTime(e.target.value);
  };
  const handleEndChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange("timeExceeds");
    setAlert("");
    setEndTime(e.target.value);
  };
  const handleChange = (name: any) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const convertToMinute = (time: any) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };
  const isTimePassed = (start: any, end: any) => {
    const startMinutes = convertToMinute(start);
    const endMinutes = convertToMinute(end);

    return startMinutes > endMinutes;
  };
  const isSameTime = (start: any, end: any) => {
    const startMinutes = convertToMinute(start);
    const endMinutes = convertToMinute(end);

    return startMinutes === endMinutes;
  };
  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      timeExceeds: "",
    };

    if (
      isTimePassed(startTime, endTime) &&
      startTime !== "00:00" &&
      endTime !== "00:00"
    ) {
      newErrors.timeExceeds = `${startTime} has passed ${endTime}`;
      valid = false;
    }
    if (
      isSameTime(startTime, endTime) &&
      startTime !== "00:00" &&
      endTime !== "00:00"
    ) {
      newErrors.timeExceeds = `Start time and end time can not be same`;
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const clearValues = () => {
    setStartTime(handleRoundTime());
    setEndTime("23:45");
    setIsBlocked(false);
    setIsBlockedInhouse(false);
    setIsBlockedOnline(false);
  };
  const handleBlockReservations = () => {
    let data = {
      OrganizationID: organizationDetails.ID,
      CloudLocationID: locationId,
      BlockDate: moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      BlockTime: {
        Start: isBlocked ? handleRoundTime() : startTime,
        End: isBlocked ? "23:45" : endTime,
      },
      Configuration: {
        Online: isBlockedOnline,
        InHouse: isBlockedInhouse,
        Menus: false,
      },
    };
    if (!isBlockedDateMatch(data)) {
      setLoading(true);
      blockReservations(data)
        .then((res) => {
          if (res) {
            setLoading(false);
            clearValues();
            setIsSuccess(true);
            getBlockedRes();
            dispatch(setIsBlockedOpen(false));
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div
      className='block-modal-overlay'
      onClick={() => {
        dispatch(setIsBlockedOpen(false));
      }}
    >
      <div
        className='block-modal-content'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='container'>
          <div className='header-container'>
            <div className='header'>
              <div>Manage Block Hours</div>
              <img
                alt=''
                src={img}
                onClick={() => {
                  dispatch(setIsBlockedOpen(false));
                }}
              />
            </div>
            <div className='date-cont'>
              {moment(date).format("dddd, Do MMM YYYY")}
            </div>
          </div>
          <div className='content'>
            <div className='header'>Block New Hours</div>
            <div className='date-cont'>
              <div>Date</div>
              <DatePickerInput
                selectedDate={date}
                setSelectedDate={(value) => {
                  setDate(moment(value).format("yyyy-MM-DD"));
                }}
              />
            </div>
            <div
              className='head'
              onClick={() => {
                setAlert("");
                setStartTime(handleRoundTime());
                setEndTime("23:45");
                setIsBlocked(!isBlocked);
                handleChange("timeExceeds");
              }}
            >
              <div className='check'>
                {isBlocked && (
                  <div className='checked'>
                    <img alt='' src={tick} />
                  </div>
                )}
              </div>
              Block Reservation for the day
            </div>
            <div className='time-container'>
              <div className='start'>
                <div>Start Time</div>
                <select
                  id='dates'
                  value={startTime}
                  onChange={handleStartChange}
                  disabled={isBlocked}
                >
                  {slots.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className='start'>
                <div>End Time</div>
                <select
                  id='dates'
                  value={endTime}
                  disabled={isBlocked}
                  onChange={handleEndChange}
                >
                  {endTimeSlots.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='error'>{errors.timeExceeds}</div>
            <div className='selections-container'>
              <div
                className='select'
                onClick={() => {
                  setAlert("");
                  setIsBlockedOnline(!isBlockedOnline);
                }}
              >
                <div className='check'>
                  {isBlockedOnline && (
                    <div className='checked'>
                      <img alt='' src={tick} />
                    </div>
                  )}
                </div>
                FOH / Online
              </div>
              <div
                className='select'
                onClick={() => {
                  setAlert("");
                  setIsBlockedInhouse(!isBlockedInhouse);
                }}
              >
                <div className='check'>
                  {isBlockedInhouse && (
                    <div className='checked'>
                      <img alt='' src={tick} />
                    </div>
                  )}
                </div>
                BOH / Internal
              </div>
            </div>
            {alert !== "" && (
              <Alert type='danger' content={`Restriction ${alert}`} />
            )}
            <div className='btn-cont'>
              <Button
                auth
                text='Block Selected Hours'
                height='40px'
                padding='0.5rem 1rem'
                fontWeight={500}
                onClick={() => {
                  if (isBlockedInhouse || isBlockedOnline) {
                    if (validateForm()) {
                      handleBlockReservations();
                    }
                  }
                }}
                loading={loading}
                disable={!isBlockedInhouse && !isBlockedOnline}
              />
            </div>
          </div>
          <div className='blocked-data-conatiner'>
            <div className='content'>
              <div className='header'>Currently Blocked Hours</div>

              {dataLoading ? (
                <div className='empty'>
                  <div className='loader' />
                </div>
              ) : blockedData.length > 0 ? (
                blockedData.map((data: any, index: number) => {
                  return (
                    <div className='blocked-content' key={index}>
                      <div className='left-cont'>
                        <div>
                          {data.BlockTime.Start} - {data.BlockTime.End}
                        </div>
                        <div className='select-opt'>
                          {data.Configuration.Online && "Online"}{" "}
                          {data.Configuration.InHouse && "In-house"}
                        </div>
                      </div>
                      <div className='right-cont'>
                        <div
                          className='delete'
                          onClick={() => {
                            if (isLoading === "") {
                              setIsLoading(data.BlockedID);
                              deleteBlockedReservations(data.BlockedID)
                                .then((res) => {
                                  if (res) {
                                    getBlockedReservations(
                                      locationId,
                                      moment(date).format("YYYY-MM-DD")
                                    ).then((res) => {
                                      if (res) {
                                        setBlockedData(res);
                                        setIsLoading("");
                                      }
                                    });
                                  }
                                })
                                .catch(() => {
                                  setIsLoading("");
                                });
                            }
                          }}
                        >
                          {isLoading === data.BlockedID ? (
                            <div className='loading-ic' />
                          ) : (
                            "Delete"
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='no-booking'>No Blocked Hours</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockingHourModal;
