import React, { useState, useEffect, useRef } from "react";
import "./DropdownComponentStyles.scss";
import arrow from "../../../assets/down.png";

interface DropdownComponentProps {
  options: any;
  onChange: (value: string) => void;
  label?: string;
  isRequired?: boolean;
}

const DropdownComponent: React.FC<DropdownComponentProps> = ({
  options,
  onChange,
  label,
  isRequired,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(
    options[0].label
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleSelect = async (option: any) => {
    setSelectedOption(option.label);
    onChange(option.value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div className='DropdownComponent' ref={dropdownRef}>
      {label !== "" && (
        <div className='label'>
          {label}{" "}
          {isRequired && (
            <span style={{ color: "red", marginLeft: "0.2rem" }}>*</span>
          )}
        </div>
      )}
      <div className='inner-container'>
        <div className='cont' onClick={handleToggle}>
          <div className='dropdown-button'>{selectedOption}</div>
          <img alt='' src={arrow} />
        </div>

        {isOpen && (
          <div className='dropdown-content'>
            {options.map((opt: any, key: number) => (
              <div key={key}>
                <div
                  className='opt'
                  onClick={() => {
                    handleSelect(opt);
                  }}
                >
                  {opt.label}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownComponent;
