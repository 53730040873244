import React from "react";
import "./InputComponentStyles.scss";

interface InputProps {
  value: string;
  label: string;
  onChange: (value: string) => void;
  disabled: boolean;
  fontSize?: string;
  placeholder?: string | "";
  margin?: boolean;
  isRequired: boolean;
  type?: string;
  isAmount?: boolean;
  isPayment?: boolean;
}

const InputComponent: React.FC<InputProps> = ({
  value,
  label,
  onChange,
  disabled,
  fontSize,
  placeholder,
  margin,
  isRequired,
  type,
  isAmount,
  isPayment,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={isPayment ? "payment-input-component " : "input-component"}>
      {label !== "" && (
        <div className='label' style={{ marginLeft: margin ? "0rem" : "" }}>
          {label}{" "}
          {isRequired && (
            <span style={{ color: "red", marginLeft: "0.2rem" }}>*</span>
          )}
        </div>
      )}
      <div className={`input-cont ${disabled ? "disabled" : ""}`}>
        {isAmount && <div className='amount'>$</div>}
        <input
          type={type || "text"}
          id={label}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          style={{ fontSize }}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default InputComponent;
