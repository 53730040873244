import React, { useState, useEffect } from "react";
import "./App.scss";
import BookingPageOne from "./view/pages/bookings/BookingPageOne";
import { Route, Routes, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BookingPageTwo from "./view/pages/bookings/BookingPageTwo";
import BookingPageThree from "./view/pages/bookings/BookingPageThree";
import Login from "./view/pages/auth/login/Login";
import ReservationListView from "./view/pages/auth/reservation/ReservationListView";
import ReservationFloorView from "./view/pages/auth/reservation/ReservationFloorView";
import NewReservation from "./view/pages/auth/reservation/NewReservation";
import TableGrouping from "./view/pages/auth/reservation/TableGrouping";
import ShiftManagement from "./view/pages/auth/reservation/ShiftManagement";
import Layout from "./view/pages/auth/reservation/Layout";
import CustomerManagement from "./view/pages/customerManagement/CustomerManagement";
import NetworkStatus from "./view/components/NetworkStatus/NetworkStatus";
import BlockingHourModal from "./view/components/ReservationComponents/blockingModal/BlockingHourModal/BlockingHourModal";
import BlockingTableModal from "./view/components/ReservationComponents/blockingModal/BlockingTableModal/BlockingTableModal";
import { useSelector } from "react-redux";
import { RootState } from "./application/store";
import BookingPayment from "./view/pages/bookings/BookingPayment";
import SuccessModal from "./view/components/ReservationComponents/errorModal/SuccessModal";
import RestrictionManagement from "./view/pages/auth/reservation/RestrictionManagement";
import PaymentRules from "./view/pages/auth/reservation/PaymentRules";

const App: React.FC = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isBlockOpen = useSelector(
    (state: RootState) => state.booking.isBlockOpen
  );
  // const isBlockTableOpen = useSelector(
  //   (state: RootState) => state.booking.isBlockTableOpen
  // );
  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);
  useEffect(() => {
    const checkValueInLocalStorage = () => {
      const orgValue = localStorage.getItem("org");
      const locValue = localStorage.getItem("wrlId");
      let isbookingWidget = location.pathname.includes("/booking/slots/");
      if ((!orgValue || !locValue || locValue === "") && !isbookingWidget) {
        localStorage.clear();
        navigate("/");
      }
    };
    checkValueInLocalStorage();

    window.addEventListener("storage", checkValueInLocalStorage);
    return () => {
      window.removeEventListener("storage", checkValueInLocalStorage);
    };
  }, []);
  return (
    <div className='App'>
      {isOnline ? "" : <NetworkStatus />}
      {isBlockOpen && <BlockingHourModal setIsSuccess={setIsSuccess} />}
      <SuccessModal
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
        onOk={() => {
          setIsSuccess(false);
          if (
            window.location.pathname === "/v1/booking/restriction-management"
          ) {
            window.location.reload();
          }
        }}
        header='Saved Successfully!'
        content='The blocking hours have been saved successfully.'
      />
      {/* {isBlockTableOpen && <BlockingTableModal />} */}
      <Routes>
        <Route path='/booking/slots/:locationId' element={<BookingPageOne />} />
        <Route path='/booking/customer-details' element={<BookingPageTwo />} />
        <Route path='/booking/payment' element={<BookingPayment />} />
        <Route path='/booking/confirmation' element={<BookingPageThree />} />
        <Route path='/' element={<Login />} />
        <Route path='/v1/booking/list-view' element={<ReservationListView />} />
        <Route
          path='/v1/booking/floor-view'
          element={<ReservationFloorView />}
        />
        <Route
          path='/v1/booking/new-reservation'
          element={<NewReservation />}
        />
        <Route path='/v1/booking/grouping' element={<TableGrouping />} />
        <Route
          path='/v1/booking/shift-management'
          element={<ShiftManagement />}
        />
        <Route path='/v1/booking/floor-layout' element={<Layout />} />
        <Route
          path='/v1/booking/customer-management'
          element={<CustomerManagement />}
        />
        <Route
          path='/v1/booking/restriction-management'
          element={<RestrictionManagement />}
        />
        <Route path='/v1/booking/payment-rules' element={<PaymentRules />} />
      </Routes>
    </div>
  );
};

export default App;
