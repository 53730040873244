import axios from "axios";
import globalValues from "../../globalValues";
import { generateToken } from "../../token/requests/Token";
export const getPaymentRules = async (locationID: string) => {
  try {
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/pre-payment/location/${locationID}`;
          await axios({
            method: "GET",
            url: url,
            headers,
          })
            .then((response) => {
              if (response.data.Result !== null) {
                resolve(response.data.Result);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Get pre payment rules Data", error);
  }
};
export const addPaymentRules = async (data: any) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/pre-payment`;
          await axios({
            method: "POST",
            url: url,
            headers,
            data,
          })
            .then((response) => {
              if (response.data.Result !== null) {
                resolve(response.data.Result);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Get Organization Data", error);
  }
};
export const deletePaymentRule = async (resId: string) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/pre-payment/${resId}`;
        await axios({
          method: "DELETE",
          url: url,
          headers,
        })
          .then((response) => {
            if (response.data.Result !== null) {
              resolve(response.data.Result);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In delete floor", error);
  }
};
