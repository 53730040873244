import React from "react";
import "./TextAreaStyles.scss";

interface TextAreaProps {
  value: string;
  label: string;
  onChange: (value: string) => void;
  minHeight?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  value,
  label,
  onChange,
  minHeight,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className='TextArea'>
      <div className='label'>{label}</div>
      <textarea
        id={label}
        value={value}
        onChange={handleChange}
        style={{ minHeight }}
      />
    </div>
  );
};

export default TextArea;
