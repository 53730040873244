import React from "react";
import "./SwitchStyles.scss";

interface SwitchProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  handleCheck?: () => void;
}
const Switch: React.FC<SwitchProps> = ({
  checked,
  setChecked,
  handleCheck,
}) => {
  return (
    <div className='SwitchComponent'>
      <label className='switch'>
        <input
          type='checkbox'
          checked={checked}
          onChange={() => {
            setChecked(!checked);
            if (handleCheck) {
              handleCheck();
            }
          }}
        />
        <span className='slider round'></span>
      </label>
    </div>
  );
};
export default Switch;
