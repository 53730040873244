import React, { useState, useEffect, useRef } from "react";
import img from "../../../assets/close-circle.svg";
import "./PaymentRulesModalStyles.scss";
import InputComponent from "../../inputcomponent/InputComponent";
import warning from "../../../assets/alert.png";
import plus from "../../../assets/create.svg";
import DropdownComponent from "../dropdown/DropdownComponent";
import Switch from "../../switch/Switch";
import Alert from "../../../pages/auth/reservation/tableDetails/Alert";
import TextArea from "../../textarea/TextArea";
import Button from "../../button/Button";
import CheckBox from "../checkBox/CheckBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../../application/store";
import { addPaymentRules } from "../../../../infra/apis/bookings/requests/PaymentRulesManagment";
import { getShiftDetails } from "../../../../infra/apis/bookings/requests/Booking";
import close from "../../../assets/close.png";
interface PaymentRulesModalProps {
  onClose: () => void;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  paymentRules: any[];
}
const PaymentRulesModal: React.FC<PaymentRulesModalProps> = ({
  onClose,
  setIsSuccess,
  paymentRules,
}) => {
  console.log(paymentRules, "paymentRules");
  const cancelPolicyOption: any[] = [
    {
      value: "PRE_AUTHORIZE",
      label: "Pre Authorize only",
    },
    {
      value: "IMMEDIATE",
      label: "Capture Immediately",
    },
    {
      value: "DELAYED",
      label: "Delay Capture",
    },
  ];
  const options: any[] = [
    {
      value: "Do not cancel",
      label: "Do not cancel",
    },
    {
      value: "10 mins",
      label: "10 mins",
    },
    {
      value: "15 mins",
      label: "15 mins",
    },
    {
      value: "30 mins",
      label: "30 mins",
    },
    {
      value: "45 mins",
      label: "45 mins",
    },
    {
      value: "1 hour",
      label: "1 hour",
    },
    {
      value: "2 hours",
      label: "2 hours",
    },
    {
      value: "3 hours",
      label: "3 hours",
    },
    {
      value: "6 hours",
      label: "6 hours",
    },
    {
      value: "12 hours",
      label: "12 hours",
    },
    {
      value: "24 hours",
      label: "24 hours",
    },
    {
      value: "36 hours",
      label: "36 hours",
    },
    {
      value: "48 hours",
      label: "48 hours",
    },
    {
      value: "72 hours",
      label: "72 hours",
    },
  ];
  const [name, setName] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [cover, setCover] = useState<string>("");
  const [timing, setTiming] = useState<string>(options[0].value);
  const [policy, setPolicy] = useState<string>(cancelPolicyOption[0].value);
  const [isPaymentPerCover, setIsPaymentPerCover] = useState<boolean>(false);
  const [isPaymentCountable, setIsPaymentCountable] = useState<boolean>(false);
  const [isActivateShift, setIsActivateShift] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [terms, setTerms] = useState<string>("");
  const [selectedShifts, setSelectedShifts] = useState<any[]>([]);
  const [openPopupIndex, setOpenPopupIndex] = useState<boolean>(false);
  const [shiftData, setShiftData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isPaymentRuleActivate, setIsPaymentRuleActivate] =
    useState<boolean>(false);
  const [errors, setErrors] = useState({
    name: "",
    amount: "",
    cover: "",
  });
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdownChange = (value: string) => {
    setTiming(value);
  };
  const handlePolicyDropdownChange = (value: string) => {
    setPolicy(value);
  };
  const handleIsActiveShift = () => {
    setSelectedShifts([]);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenPopupIndex(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getShiftDetails(locationId).then((shift: any) => {
      if (shift) {
        let activeShifts = shift.filter((val: any) => val.IsActive);
        setShiftData(activeShifts);
      }
    });
  };
  const convertToMinutes = (value: string) => {
    const [amount, unit] = value.split(" ");
    const number = parseInt(amount, 10);

    switch (unit) {
      case "mins":
        return number;
      case "hour":
      case "hours":
        return number * 60;
      default:
        return 0;
    }
  };
  const isValid = (shift: any) => {
    return selectedShifts.some((item) => item.Id === shift.ShiftManagementID);
  };
  const morePopup = () => {
    if (!openPopupIndex) return null;
    return (
      <div className='dropdown-content' ref={dropdownRef}>
        {shiftData.map((shift: any) => {
          return (
            <div
              className='item'
              onClick={() => {
                const updated = selectedShifts.some(
                  (item: any) => item.Id === shift.ShiftManagementID
                )
                  ? selectedShifts.filter(
                      (item: any) => item.Id !== shift.ShiftManagementID
                    )
                  : [
                      ...selectedShifts,
                      {
                        Id: shift.ShiftManagementID,
                        isEnabled: true,
                        Name: shift.Name,
                      },
                    ];

                setSelectedShifts(updated);
              }}
            >
              <CheckBox checked={isValid(shift)} onClick={() => {}} label='' />
              {shift.Name}
            </div>
          );
        })}
      </div>
    );
  };
  const handleSave = () => {
    setLoading(true);

    let data = {
      PaymentRuleID: "",
      Name: name,
      OrganizationID: organizationDetails.ID,
      CloudLocationID: locationId,
      Shift: selectedShifts,
      Rules: {
        Type: policy,
        MinimumReservationCover: Number(cover),
        PaymentPerCover: isPaymentPerCover,
        PaymentPerCountableField: isPaymentCountable,
        PaymentAmountPerCover: {
          Amount: Number(amount) * 100,
          CurrencyCode: "GBP",
          Text: "£" + amount,
          Symbol: "£",
        },
        AutoCancellation: {
          Duration:
            timing === "Do not cancel"
              ? convertToMinutes("0 mins")
              : convertToMinutes(timing),
        },
      },
      Terms: [terms],
      IsDeleted: false,
      IsActive: isPaymentRuleActivate,
    };
    addPaymentRules(data)
      .then((res) => {
        if (res) {
          setLoading(false);
          clearValues();
          onClose();
          setIsSuccess(true);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const clearValues = () => {
    setName("");
    setAmount("");
    setCover("");
    setTiming(options[0].value);
    setPolicy(cancelPolicyOption[0].value);
    setIsPaymentCountable(false);
    setIsPaymentPerCover(false);
    setIsActivateShift(false);
    setSelectedShifts([]);
    setIsPaymentRuleActivate(false);
  };
  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      name: "",
      amount: "",
      cover: "",
    };

    if (name === "") {
      newErrors.name = `Required`;
      valid = false;
    }
    if (
      paymentRules.filter(
        (rule: any) => rule.Name.toLowerCase() === name.toLowerCase()
      ).length > 0
    ) {
      newErrors.name = `Payment rule name already exist`;
      valid = false;
    }
    if (amount === "") {
      newErrors.amount = `Required`;
      valid = false;
    }
    if (Number(amount) < 0) {
      newErrors.amount = `Payment amount can't be a negative value`;
      valid = false;
    }
    if (cover === "") {
      newErrors.cover = `Required`;
      valid = false;
    }
    if (Number(cover) < 0) {
      newErrors.cover = `Minimum reservation cover can't be a negative value`;
      valid = false;
    }
    if (Number(cover) < 1) {
      newErrors.cover = `Minimum reservation cover can't be less than 1`;
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const handleChange = (name: any) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  return (
    <div className='payment-rules-modal-overlay'>
      <div
        className='payment-rules-modal-content'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='container'>
          <div className='header'>
            Create Payment Rule
            <img
              alt=''
              src={img}
              onClick={() => {
                onClose();
              }}
            />
          </div>
          <div className='content'>
            <div className='payment-rules-input'>
              <InputComponent
                label='Payment Rule Name'
                value={name}
                onChange={(value) => {
                  handleChange("name");
                  setName(value);
                }}
                disabled={false}
                fontSize='14px'
                margin
                isRequired
                isPayment
              />
              <div className='error'>{errors.name}</div>
            </div>
            <div className='payment-columns-container'>
              <div className='payment-columns'>
                <InputComponent
                  label='Payment Amount'
                  value={amount}
                  onChange={(value) => {
                    handleChange("amount");
                    setAmount(value);
                  }}
                  disabled={false}
                  fontSize='14px'
                  margin
                  isRequired
                  type='number'
                  isAmount
                  isPayment
                />
                <div className='error' style={{ marginBottom: "-2rem" }}>
                  {errors.amount}
                </div>
              </div>
              <div className='payment-columns-right'>
                <InputComponent
                  label='Minimum Reservation Covers'
                  value={cover}
                  onChange={(value) => {
                    handleChange("cover");
                    setCover(value);
                  }}
                  disabled={false}
                  fontSize='14px'
                  margin
                  isRequired
                  isPayment
                  type='number'
                />
                <div className='error'>{errors.cover}</div>
              </div>
            </div>
            <div className='payment-rules-input'>
              <DropdownComponent
                options={options}
                onChange={handleDropdownChange}
                label='Payment Timing'
              />
            </div>
            <div className='payment-columns-container'>
              <div className='payment-columns-toggle'>
                <div> Payment per Cover</div>
                <Switch
                  checked={isPaymentPerCover}
                  setChecked={setIsPaymentPerCover}
                />
              </div>
              <div className='payment-columns-toggle'>
                <div>Payment per Countable Field</div>
                <Switch
                  checked={isPaymentCountable}
                  setChecked={setIsPaymentCountable}
                />
                <div
                  className='alert'
                  onClick={() => {
                    setAlertOpen(true);
                  }}
                >
                  <img alt='' src={warning} />
                </div>
              </div>
            </div>
            {alertOpen && (
              <Alert
                type='warning'
                content='Payment amount will be calculated based on countable fields, replacing the standard amount.'
                closeIcon
                onClick={() => {
                  setAlertOpen(false);
                }}
              />
            )}
            <div className='payment-rules-input'>
              <DropdownComponent
                options={cancelPolicyOption}
                onChange={handlePolicyDropdownChange}
                label='Auto Cancel Policy'
              />
            </div>
            <div className='payment-rules-input'>
              <div className='label-header'>Terms of service</div>
              <div className='rules-sub-text'>
                These terms will be visible to customers when they make a
                reservation.
              </div>
              <div className='text-area-content'>
                <TextArea
                  label=''
                  value={terms}
                  onChange={(value) => {
                    setTerms(value);
                  }}
                  minHeight='7rem'
                />
              </div>
            </div>
            <div className='payment-columns-container'>
              <div className='payment-columns-toggle'>
                <div>Activate Shift</div>
                <Switch
                  checked={isActivateShift}
                  setChecked={setIsActivateShift}
                  handleCheck={handleIsActiveShift}
                />
              </div>
            </div>
            {isActivateShift && (
              <div className='payment-rules-input'>
                <div className='label-header'>
                  Selected Shifts
                  <img
                    alt=''
                    src={plus}
                    className='plus-icon'
                    onClick={() => {
                      setOpenPopupIndex(true);
                    }}
                  />
                  {morePopup()}
                </div>
                {selectedShifts.length === 0 ? (
                  <div className='rules-sub-text'>No shifts Selected.</div>
                ) : (
                  <div className='shifts-row'>
                    {selectedShifts.map((shift) => {
                      return (
                        <div className='shift'>
                          {shift.Name}
                          <div
                            className='close-icon'
                            onClick={() => {
                              let updated = selectedShifts.filter(
                                (item) => item.Id !== shift.Id
                              );
                              setSelectedShifts(updated);
                            }}
                          >
                            <img alt='' src={close} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <div className='payment-rules-input'>
              <CheckBox
                checked={isPaymentRuleActivate}
                onClick={() => {
                  setIsPaymentRuleActivate(!isPaymentRuleActivate);
                }}
                label='Payment Rule Active'
              />
            </div>
            <div className='payment-rules-btn-container'>
              <Button
                text='Create Payment Rule'
                auth
                height='40px'
                onClick={() => {
                  if (validateForm()) {
                    handleSave();
                  }
                }}
                loading={loading}
              />
              <Button
                text='Cancel'
                border
                height='40px'
                onClick={() => {
                  clearValues();
                  onClose();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentRulesModal;
