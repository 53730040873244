import React, { useState, useEffect, useRef } from "react";
import "./ReservationStyles.scss";
import Drawer from "../../../components/ReservationComponents/drawer/Drawer";
import SideBar from "../../../components/ReservationComponents/sideBar/SideBar";
import TopBar from "../../../components/ReservationComponents/topBar/TopBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../application/store";
import more from "../../../assets/more.png";
import deleteIc from "../../../assets/Delete.svg";
import duplicateIc from "../../../assets/copy.svg";
import clockIc from "../../../assets/time.svg";
import RestrictionModal from "../../../components/ReservationComponents/restrictionModal/RestrictionModal";
import RestrictTablesModal from "../../../components/ReservationComponents/restrictionModal/restrictTablesModal/RestrictTablesModal";
import {
  deleteBlockedReservations,
  getAllBlockedReservations,
  getFloorManagementDetails,
} from "../../../../infra/apis/bookings/requests/Booking";
import { setAllFloorDetails } from "../../../../application/reducer/bookingSlice";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/ReservationComponents/errorModal/DeleteModal";
import moment from "moment";
import SuccessModal from "../../../components/ReservationComponents/errorModal/SuccessModal";

const RestrictionManagement: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(
    localStorage.getItem("drawerOpen")
      ? JSON.parse(localStorage.getItem("drawerOpen") || "false")
      : false
  );
  const [screenWidth, setScreenWidth] = useState<any>(992);
  const [loading, setLoading] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth - 194);
  const [openPopupIndex, setOpenPopupIndex] = useState<string>("");
  const [refId, setRefId] = useState<string>("");
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [isRestrictOpen, setIsRestrictOpen] = useState<boolean>(false);
  const [isEditRestrictOpen, setIsEditRestrictOpen] = useState<boolean>(false);
  const [isDuplicateRestrictOpen, setIsDuplicateRestrictOpen] =
    useState<boolean>(false);

  const [isRestrictTableOpen, setIsRestrictTableOpen] =
    useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [blockedData, setBlockedData] = useState<any>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [selectedTables, setSelectedTables] = useState<any>([]);
  const [selected, setSelected] = useState<any>(null);

  const islocationSwitching = useSelector(
    (state: RootState) => state.booking.islocationSwitching
  );
  const allFloorDetails = useSelector(
    (state: RootState) => state.booking.allFloorDetails
  );
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
      setWidth(window.innerWidth - 194);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenPopupIndex("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  useEffect(() => {
    localStorage.removeItem("selectedDate");
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails === "" || userDetails === null) {
      navigate("/");
    } else {
      getFloorData();
      getBlockedRes();
    }
  }, [locationId || "initial"]);
  const getBlockedRes = async () => {
    setLoading(true);
    await getAllBlockedReservations(locationId)
      .then((res) => {
        if (res) {
          setBlockedData(res);
          setLoading(false);
        } else {
          setBlockedData([]);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getFloorData = async () => {
    await getFloorManagementDetails().then((response: any) => {
      dispatch(setAllFloorDetails(response));
    });
  };
  const morePopup = (value: any) => {
    if (openPopupIndex !== value.ID) return null;
    return (
      <div className='dropdown-content' ref={dropdownRef}>
        <div
          className='item'
          onClick={() => {
            setOpenPopupIndex("");
            setIsEditRestrictOpen(true);
          }}
        >
          <img alt='' src={clockIc} />
          Manage Blocked Hours
        </div>
        <div
          className='item'
          onClick={() => {
            setOpenPopupIndex("");
            setIsDuplicateRestrictOpen(true);
          }}
        >
          <img alt='' src={duplicateIc} />
          Duplicate
        </div>
        <div
          className='item'
          onClick={() => {
            setIsDeleteOpen(true);
          }}
        >
          <img alt='' src={deleteIc} />
          Delete
        </div>
      </div>
    );
  };
  const getTrueValues = (obj: any) => {
    return Object.keys(obj).filter((key) => obj[key]);
  };
  return (
    <div className='Reservation'>
      {" "}
      {islocationSwitching ? (
        <div className='empty'>
          <div className='loader' />
        </div>
      ) : screenWidth > 900 ? (
        <>
          {isDrawerOpen ? (
            <Drawer
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selectedMenu={"RESTRICTION MANAGEMENT"}
            />
          ) : (
            <SideBar
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selected={"RESTRICTION MANAGEMENT"}
            />
          )}
          <div
            className='reservation-content'
            style={{ marginLeft: isDrawerOpen ? "16.25rem" : "5rem" }}
          >
            <TopBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title='Restrictions Management'
              isOpenDrawer={isDrawerOpen}
            />
            {loading ? (
              <div className='loader' />
            ) : (
              <div className='restriction-view'>
                <div
                  className='restriction-top-bar'
                  style={{ width: isDrawerOpen ? width : "" }}
                >
                  <div className='left-container'>
                    Manage Restrictions by specifying the hours to be restricted
                  </div>
                  <div className='right-container'>
                    <div
                      className='block-hours-btn'
                      onClick={() => {
                        setIsRestrictOpen(true);
                      }}
                    >
                      Block New Hours
                    </div>
                  </div>
                </div>
                <div className='restriction-table-view'>
                  <div className='restriction-table'>
                    <table id='bookings'>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Block Type</th>
                        <th>Blocked Tables Count </th>
                        <th></th>
                      </tr>
                      {blockedData.length > 0 ? (
                        blockedData.map((value: any) => {
                          return (
                            <tr style={{ cursor: "pointer" }}>
                              <td>
                                {moment(value.BlockDate).format("YYYY-MM-DD")}
                              </td>
                              <td>
                                {value.BlockTime.Start} - {value.BlockTime.End}
                              </td>
                              <td>
                                <div>
                                  {getTrueValues(value.Configuration).join(
                                    ", "
                                  )}
                                </div>
                              </td>
                              <td>0</td>
                              <td>
                                <div className='more-icon'>
                                  <img
                                    alt=''
                                    src={more}
                                    onClick={() => {
                                      setSelected(value);
                                      setRefId(value.BlockedID);
                                      setOpenPopupIndex(
                                        openPopupIndex === value.ID
                                          ? ""
                                          : value.ID
                                      );
                                    }}
                                  />
                                  {morePopup(value)}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className='empty-container'>
                          <td colSpan={6}> No data available</td>
                        </tr>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
      {isRestrictOpen && (
        <RestrictionModal
          onClose={() => {
            setIsRestrictOpen(false);
            setSelectedTables([]);
          }}
          setIsRestrictTableOpen={setIsRestrictTableOpen}
          setIsSuccess={setIsSuccess}
          selectedTables={selectedTables}
          blockedHours={blockedData}
          isEdit={false}
          isDuplicate={false}
          selected={null}
        />
      )}
      {isDuplicateRestrictOpen && (
        <RestrictionModal
          onClose={() => {
            setIsDuplicateRestrictOpen(false);
            setSelectedTables([]);
            setSelected(null);
          }}
          setIsRestrictTableOpen={setIsDuplicateRestrictOpen}
          setIsSuccess={setIsSuccess}
          selectedTables={selectedTables}
          blockedHours={blockedData?.filter(
            (val: any) => val.BlockedID !== selected.BlockedID
          )}
          isEdit={false}
          isDuplicate={true}
          selected={selected}
        />
      )}
      {isEditRestrictOpen && (
        <RestrictionModal
          onClose={() => {
            setIsEditRestrictOpen(false);
            setSelectedTables([]);
            setSelected(null);
          }}
          setIsRestrictTableOpen={setIsEditRestrictOpen}
          setIsSuccess={setIsSuccess}
          selectedTables={selectedTables}
          blockedHours={blockedData?.filter(
            (val: any) => val.BlockedID !== selected.BlockedID
          )}
          isEdit={true}
          isDuplicate={false}
          selected={selected}
        />
      )}
      {isRestrictTableOpen && (
        <RestrictTablesModal
          allFloorDetails={allFloorDetails}
          onClose={() => {
            setIsRestrictTableOpen(false);
          }}
          setIsRestrictOpen={setIsRestrictOpen}
          setSelectedTables={setSelectedTables}
          selectedTables={selectedTables}
        />
      )}
      <DeleteModal
        isOpen={isDeleteOpen}
        onDelete={() => {
          setDeleteLoading(true);
          deleteBlockedReservations(refId)
            .then((res) => {
              if (res) {
                setIsDeleteOpen(false);
                setRefId("");
                setIsDeleteSuccess(true);
                setDeleteLoading(false);
              } else {
                setDeleteLoading(false);
              }
            })
            .catch(() => {
              setDeleteLoading(false);
            });
        }}
        onClose={() => {
          setIsDeleteOpen(false);
          setDeleteLoading(false);
        }}
        isLoading={deleteLoading}
        content='Are you sure that you want to delete this?'
      />
      <SuccessModal
        isOpen={isDeleteSuccess}
        onClose={() => {
          getBlockedRes();
          setIsDeleteSuccess(false);
        }}
        onOk={() => {
          getBlockedRes();
          setIsDeleteSuccess(false);
        }}
        header='Successfully Deleted!'
        content='Successfully Blocking Hours deleted'
      />
      <SuccessModal
        isOpen={isSuccess}
        onClose={() => {
          getBlockedRes();
          setIsSuccess(false);
        }}
        onOk={() => {
          getBlockedRes();
          setIsSuccess(false);
        }}
        header='Saved Successfully!'
        content='The blocking hours have been saved successfully.'
      />
    </div>
  );
};
export default RestrictionManagement;
