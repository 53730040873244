import React, { useState } from "react";
import "./RestrictTablesModalStyles.scss";
import img from "../../../../assets/close-circle.svg";
import RoomTablesDropdown from "./RoomTablesDropdown";
import Button from "../../../button/Button";
interface RestrictTablesModalProps {
  allFloorDetails: any;
  onClose: () => void;
  setIsRestrictOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTables: React.Dispatch<React.SetStateAction<any>>;
  selectedTables: any;
}
const RestrictTablesModal: React.FC<RestrictTablesModalProps> = ({
  allFloorDetails,
  onClose,
  setIsRestrictOpen,
  setSelectedTables,
  selectedTables,
}) => {
  const [selected, setSelected] = useState<any>(selectedTables);

  return (
    <div className='restrict-tables-modal-overlay '>
      <div className='restrict-tables-modal-content'>
        <div className='container'>
          <div className='header'>
            Add/Manage Tables
            <img
              alt=''
              src={img}
              onClick={() => {
                setIsRestrictOpen(true);
                onClose();
              }}
            />
          </div>
          <div className='content'>
            {[...allFloorDetails]
              .sort((a, b) => a.Priority - b.Priority)
              .map((value: any) => {
                return (
                  <RoomTablesDropdown
                    value={value}
                    selected={selected}
                    setSelected={setSelected}
                  />
                );
              })}
            <Button
              text='Set Tables'
              auth
              onClick={() => {
                setSelectedTables(selected);
                setIsRestrictOpen(true);
                onClose();
                setSelected([]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RestrictTablesModal;
