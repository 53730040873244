import React, { useEffect, useState } from "react";
import "./RestrictionModalStyles.scss";
import img from "../../../assets/close-circle.svg";
import InputDatePicker from "../inputDatePicker/InputDatePicker";
import Button from "../../button/Button";
import moment from "moment";
import { blockReservations } from "../../../../infra/apis/bookings/requests/Booking";
import { useSelector } from "react-redux";
import { RootState } from "../../../../application/store";
import Alert from "../../../pages/auth/reservation/tableDetails/Alert";
interface RestrictionModalProps {
  onClose: () => void;
  setIsRestrictTableOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTables: any;
  blockedHours: any[];
  isEdit: boolean;
  isDuplicate: boolean;
  selected: any;
}

const RestrictionModal: React.FC<RestrictionModalProps> = ({
  onClose,
  setIsRestrictTableOpen,
  setIsSuccess,
  selectedTables,
  blockedHours,
  isEdit,
  isDuplicate,
  selected,
}) => {
  const [date, setDate] = useState<string>(new Date().toDateString());
  const [dateDupe, setDateDupe] = useState<string>(new Date().toDateString());
  const [alert, setAlert] = useState<string>("");
  const [duplicate, setDuplicate] = useState<boolean>(false);
  const [isDuplicateAlert, setIsDuplicateAlert] = useState<boolean>(false);

  const blockedData = blockedHours.map((blocked) => ({
    OrganizationID: blocked.OrganizationID,
    CloudLocationID: blocked.CloudLocationID,
    BlockDate: blocked.BlockDate,
    BlockTime: {
      Start: blocked.BlockTime.Start,
      End: blocked.BlockTime.End,
    },
    Configuration: {
      Online: blocked.Configuration.Online,
      InHouse: blocked.Configuration.InHouse,
      Menus: blocked.Configuration.Menus,
    },
  }));

  const roundToNextQuarterHour = (time: moment.Moment): moment.Moment => {
    const minutes = time.minutes();
    const nextQuarterHour = Math.ceil(minutes / 15) * 15;

    if (nextQuarterHour === 60) {
      return time.add(1, "hour").startOf("hour").seconds(0);
    }

    return time.minutes(nextQuarterHour).seconds(0);
  };
  const handleRoundTime = () => {
    const time = moment();
    const rounded = roundToNextQuarterHour(time);
    return !moment(date).isSame(moment(), "day")
      ? "00:00"
      : rounded.format("HH:mm");
  };
  const generateTimeSlots = (startTime: string, endTime: string): string[] => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const timeSlots: string[] = [];
    while (start <= end) {
      const formattedTime = start.toTimeString().slice(0, 5);
      timeSlots.push(formattedTime);
      start.setMinutes(start.getMinutes() + 15);
    }
    return timeSlots;
  };
  const [startTime, setStartTime] = useState<string>(handleRoundTime());
  const [endTime, setEndTime] = useState<string>("23:45");
  const [isBlockedOnline, setIsBlockedOnline] = useState(false);
  const [isBlockedInhouse, setIsBlockedInhouse] = useState(false);
  const [isBlockedMenus, setIsBlockedMenus] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    timeExceeds: "",
    type: "",
  });
  const generateNewStart = () => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    start.setMinutes(start.getMinutes() + 15);
    return moment(start).format("HH:mm");
  };
  const slots = generateTimeSlots(handleRoundTime(), "23:30");
  const endTimeSlots = generateTimeSlots(generateNewStart(), "23:45");
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  useEffect(() => {
    setAlert("");
    setStartTime(handleRoundTime());
    setEndTime("23:45");
    setDuplicate(false);
    setIsDuplicateAlert(false);
  }, [dateDupe]);
  useEffect(() => {
    if (isEdit && selected) {
      setDate(selected?.BlockDate);
      setStartTime(selected?.BlockTime?.Start);
      setEndTime(selected?.BlockTime?.End);
      setIsBlockedInhouse(selected?.Configuration?.InHouse);
      setIsBlockedOnline(selected?.Configuration?.Online);
      setIsBlockedMenus(selected?.Configuration?.Menus);
    }
  }, [isEdit]);
  useEffect(() => {
    if (isDuplicate && selected) {
      setDuplicate(true);
      setDate(selected?.BlockDate);
      setStartTime(selected?.BlockTime?.Start);
      setEndTime(selected?.BlockTime?.End);
      setIsBlockedInhouse(selected?.Configuration?.InHouse);
      setIsBlockedOnline(selected?.Configuration?.Online);
      setIsBlockedMenus(selected?.Configuration?.Menus);
    }
  }, [isDuplicate]);
  const handleStartChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAlert("");
    handleChange("timeExceeds");
    setStartTime(e.target.value);
    setDuplicate(false);
  };
  const handleEndChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAlert("");
    handleChange("timeExceeds");
    setEndTime(e.target.value);
    setDuplicate(false);
  };
  const isBlockedDateMatch = (newBlock: any) => {
    return blockedData.some((block) => {
      const issues: string[] = [];
      let errorMessage = "";
      const isSameOrganizationAndLocation =
        block.OrganizationID === newBlock.OrganizationID &&
        block.CloudLocationID === newBlock.CloudLocationID;

      const isSameBlockDate =
        new Date(block.BlockDate).toISOString().split("T")[0] ===
        new Date(newBlock.BlockDate).toISOString().split("T")[0];
      if (isSameBlockDate) {
        issues.push(
          `date matches with the existing block data (${
            new Date(block.BlockDate).toISOString().split("T")[0]
          })`
        );
      }
      const existingStart = block.BlockTime.Start;
      const existingEnd = block.BlockTime.End;
      const newStart = newBlock.BlockTime.Start;
      const newEnd = newBlock.BlockTime.End;

      const isTimeOverlap =
        (newStart >= existingStart && newStart <= existingEnd) ||
        (newEnd >= existingStart && newEnd <= existingEnd) ||
        (existingStart >= newStart && existingStart <= newEnd);
      if (isTimeOverlap) {
        issues.push("overlaps with the block time");
      }
      const isOnlineMatch =
        block.Configuration.Online === true &&
        newBlock.Configuration.Online === true;
      const isMenusMatch =
        block.Configuration.Menus === true &&
        newBlock.Configuration.Menus === true;
      const isInhouseMatch =
        block.Configuration.InHouse === true &&
        newBlock.Configuration.InHouse === true;
      if (isOnlineMatch || isMenusMatch || isInhouseMatch) {
        const configurations = [
          isOnlineMatch ? "Online" : "",
          isInhouseMatch ? "InHouse" : "",
          isMenusMatch ? "Menus" : "",
        ]
          .filter(Boolean)
          .join(", ");
        issues.push(`and has matching configurations (${configurations})`);
      }

      errorMessage = `${issues.join(", ")}`;
      let invalid =
        isSameOrganizationAndLocation &&
        isSameBlockDate &&
        isTimeOverlap &&
        (isOnlineMatch || isMenusMatch || isInhouseMatch);
      if (invalid) {
        setAlert(errorMessage);
      } else {
        setAlert("");
      }
      return invalid;
    });
  };

  const handleChange = (name: any) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const convertToMinute = (time: any) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };
  const isTimePassed = (start: any, end: any) => {
    const startMinutes = convertToMinute(start);
    const endMinutes = convertToMinute(end);

    return startMinutes > endMinutes;
  };
  const isSameTime = (start: any, end: any) => {
    const startMinutes = convertToMinute(start);
    const endMinutes = convertToMinute(end);

    return startMinutes === endMinutes;
  };
  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      timeExceeds: "",
      type: "",
    };
    if (!isBlockedOnline && !isBlockedInhouse && !isBlockedMenus) {
      newErrors.timeExceeds = `Please select one block type`;
      valid = false;
    }
    if (
      isTimePassed(startTime, endTime) &&
      startTime !== "00:00" &&
      endTime !== "00:00"
    ) {
      newErrors.timeExceeds = `${startTime} has passed ${endTime}`;
      valid = false;
    }
    if (
      isSameTime(startTime, endTime) &&
      startTime !== "00:00" &&
      endTime !== "00:00"
    ) {
      newErrors.timeExceeds = `Start time and end time can not be same`;
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const clearValues = () => {
    setDate(new Date().toDateString());
    setDateDupe(new Date().toDateString());
    setStartTime(handleRoundTime());
    setEndTime("23:45");
    setIsBlockedInhouse(false);
    setIsBlockedOnline(false);
    setIsBlockedMenus(false);
    setAlert("");
  };
  const handleBlockReservations = () => {
    let data = {
      ID: selected ? selected.ID : "",
      BlockID: selected ? selected.BlockID : "",
      OrganizationID: organizationDetails.ID,
      CloudLocationID: locationId,
      BlockDate: moment(date).format("YYYY-MM-DDT00:00:00[Z]"),
      BlockTime: {
        Start: startTime,
        End: endTime,
      },
      Configuration: {
        Online: isBlockedOnline,
        InHouse: isBlockedInhouse,
        Menus: isBlockedMenus,
      },
      // Tables: selectedTables ? selectedTables.map((item: any) => item.ID) : [],
    };

    if (!isBlockedDateMatch(data)) {
      if (isDuplicate || isEdit) {
        const d1 = new Date(moment(date).format("YYYY-MM-DDT00:00:00[Z]"));
        const d2 = new Date();
        if (d1 < d2) {
          setIsDuplicateAlert(true);
        } else {
          setIsDuplicateAlert(false);
          block(data);
        }
      } else {
        block(data);
      }
    }
  };
  const block = (data: any) => {
    setLoading(true);
    blockReservations(data)
      .then((res) => {
        if (res) {
          setLoading(false);
          clearValues();
          onClose();
          setIsSuccess(true);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const groupTablesByRoomName = () => {
    const groupedRooms: any = [];

    selectedTables.forEach((item: any) => {
      let room = groupedRooms.find((r: any) => r.RoomID === item.RoomID);

      if (!room) {
        room = {
          RoomID: item.RoomID,
          Room: item.Room,
          Tables: [],
        };
        groupedRooms.push(room);
      }

      room.Tables.push({ Table: item.Table, ID: item.ID });
    });

    return groupedRooms;
  };
  console.log(selectedTables);
  return (
    <div className='restrict-modal-overlay'>
      <div
        className='restrict-modal-content'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='container'>
          <div className='header'>
            {isEdit
              ? "Edit Restriction"
              : isDuplicate
              ? "Duplicate Restriction"
              : "Create New Restriction"}
            <img
              alt=''
              src={img}
              onClick={() => {
                onClose();
              }}
            />
          </div>
          <div className='content'>
            <div className='date-container'>
              <div className='start'>
                Date
                <InputDatePicker
                  selectedDate={date}
                  setSelectedDate={(value) => {
                    setDate(value);
                    setDateDupe(value);
                  }}
                />
              </div>
            </div>
            <div className='time-container'>
              <div className='start'>
                <div>Start Time</div>
                <select
                  id='dates'
                  value={startTime}
                  onChange={handleStartChange}
                >
                  {slots.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className='start'>
                <div>End Time</div>
                <select id='dates' value={endTime} onChange={handleEndChange}>
                  {endTimeSlots.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='error'>{errors.timeExceeds}</div>
            <div className='block-type-container'>
              <div>
                Block Type
                <span style={{ color: "red", marginLeft: "0.2rem" }}>*</span>
              </div>
              <div className='block-type-list-container'>
                <div
                  className={`block-type ${isBlockedOnline ? "selected" : ""}`}
                  onClick={() => {
                    setIsDuplicateAlert(false);
                    setDuplicate(false);
                    setAlert("");
                    setIsBlockedOnline(!isBlockedOnline);
                  }}
                >
                  Online
                </div>
                <div
                  className={`block-type ${isBlockedInhouse ? "selected" : ""}`}
                  onClick={() => {
                    setIsDuplicateAlert(false);
                    setDuplicate(false);
                    setAlert("");
                    setIsBlockedInhouse(!isBlockedInhouse);
                  }}
                >
                  In House
                </div>{" "}
                <div
                  className={`block-type-end ${
                    isBlockedMenus ? "selected " : ""
                  }`}
                  onClick={() => {
                    setIsDuplicateAlert(false);
                    setDuplicate(false);
                    setAlert("");
                    setIsBlockedMenus(!isBlockedMenus);
                  }}
                >
                  Menus
                </div>
              </div>
              <div className='error'>{errors.type}</div>
            </div>
            <div className='block-tables-container'>
              {/* <div className='header-tables-list'>Tables</div>
              <div className='tables-list'>
                {groupTablesByRoomName().map((table: any) => {
                  return (
                    <div>
                      <b>{table.Room}: </b>
                      {table.Tables.map((val: any) => val.Table).join(", ")}
                    </div>
                  );
                })}
              </div>
              <Button
                text='Add/Manage Tables'
                border
                width='max-content'
                padding='20px'
                onClick={() => {
                  onClose();
                  setIsRestrictTableOpen(true);
                }}
              /> */}
            </div>
            {duplicate && (
              <div className='error' style={{ marginBottom: "10px" }}>
                Cannot duplicate as it overlaps with an existing restriction.
              </div>
            )}
            {alert !== "" && (
              <Alert type='danger' content={`Restriction ${alert}`} />
            )}
            {isDuplicateAlert && (
              <Alert
                type='danger'
                content={
                  "The restriction date has already passed. Please select a valid date."
                }
              />
            )}
            <Button
              text='Block New Hours'
              auth
              disable={!isBlockedInhouse && !isBlockedOnline && !isBlockedMenus}
              onClick={() => {
                if (isBlockedInhouse || isBlockedOnline || isBlockedMenus) {
                  if (validateForm()) {
                    handleBlockReservations();
                  }
                }
              }}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RestrictionModal;
