import React from "react";
import tick from "../../../assets/checkmark.png";
import "./CheckBoxStyles.scss";
interface CheckBoxProps {
  onClick: () => void;
  checked: boolean;
  label: string;
}
const CheckBox: React.FC<CheckBoxProps> = ({ onClick, checked, label }) => {
  return (
    <div className='CheckBox'>
      <div className='check' onClick={onClick}>
        {checked && (
          <div className='checked'>
            <img alt='' src={tick} />
          </div>
        )}
      </div>
      {label}
    </div>
  );
};
export default CheckBox;
