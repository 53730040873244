import React, { useState, useEffect, useRef } from "react";
import "./ReservationStyles.scss";
import Drawer from "../../../components/ReservationComponents/drawer/Drawer";
import SideBar from "../../../components/ReservationComponents/sideBar/SideBar";
import TopBar from "../../../components/ReservationComponents/topBar/TopBar";
import { useSelector } from "react-redux";
import { RootState } from "../../../../application/store";
import Button from "../../../components/button/Button";
import SearchInput from "../../../components/ReservationComponents/searchInput/SearchInput";
import PaymentRulesModal from "../../../components/ReservationComponents/paymentRulesModal/PaymentRulesModal";
import { useNavigate } from "react-router-dom";
import {
  deletePaymentRule,
  getPaymentRules,
} from "../../../../infra/apis/bookings/requests/PaymentRulesManagment";
import SuccessModal from "../../../components/ReservationComponents/errorModal/SuccessModal";
import more from "../../../assets/more.png";
import deleteIc from "../../../assets/Delete.svg";
import DeleteModal from "../../../components/ReservationComponents/errorModal/DeleteModal";

const PaymentRules: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(
    localStorage.getItem("drawerOpen")
      ? JSON.parse(localStorage.getItem("drawerOpen") || "false")
      : false
  );
  const [screenWidth, setScreenWidth] = useState<any>(992);
  const [loading, setLoading] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth - 194);
  const [value, setValue] = useState<string>("");
  const [newModalOpen, setIsNewModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [lumpData, setLumpData] = useState<any>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [paymentRules, setPaymentRules] = useState<any>([]);
  const [lumpPaymentRules, setLumpPaymentRules] = useState<any>([]);
  const [openPopupIndex, setOpenPopupIndex] = useState<string>("");
  const [refId, setRefId] = useState<string>("");
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const islocationSwitching = useSelector(
    (state: RootState) => state.booking.islocationSwitching
  );
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
      setWidth(window.innerWidth - 194);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenPopupIndex("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  useEffect(() => {
    localStorage.removeItem("selectedDate");
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails === "" || userDetails === null) {
      navigate("/");
    } else {
      getData();
    }
  }, [locationId || "initial"]);
  const getData = async () => {
    setLoading(true);
    await getPaymentRules(locationId)
      .then((res: any) => {
        if (res) {
          let preData = res.filter(
            (value: any) => value?.Rules?.PaymentPerCover
          );
          setPaymentRules(preData);
          setData(preData);
          let lData = res.filter(
            (value: any) => !value?.Rules?.PaymentPerCover
          );
          setLumpPaymentRules(lData);
          setLumpData(lData);
          setLoading(false);
        } else {
          setPaymentRules([]);
          setData([]);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const convertMinutesToString = (minutes: number): string => {
    if (minutes < 60) {
      return `${minutes} mins`;
    } else {
      const hours = minutes / 60;
      return hours === 1 ? `${hours} hour` : `${hours} hours`;
    }
  };
  const morePopup = (value: any) => {
    if (openPopupIndex !== value.ID) return null;
    return (
      <div className='dropdown-content' ref={dropdownRef}>
        <div
          className='item'
          onClick={() => {
            setIsDeleteOpen(true);
          }}
        >
          <img alt='' src={deleteIc} />
          Delete
        </div>
      </div>
    );
  };
  return (
    <div className='Reservation'>
      {" "}
      {islocationSwitching ? (
        <div className='empty'>
          <div className='loader' />
        </div>
      ) : screenWidth > 900 ? (
        <>
          {isDrawerOpen ? (
            <Drawer
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selectedMenu={"PAYMENT RULES"}
            />
          ) : (
            <SideBar
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selected={"PAYMENT RULES"}
            />
          )}
          <div
            className='reservation-content'
            style={{ marginLeft: isDrawerOpen ? "16.25rem" : "5rem" }}
          >
            <TopBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title='Payment Rules'
              isOpenDrawer={isDrawerOpen}
            />
            {loading ? (
              <div className='loader' />
            ) : (
              <div className='payment-rules-view'>
                <div
                  className='payment-rules-top-bar'
                  style={{ width: isDrawerOpen ? width : "" }}
                >
                  <div className='left-container'>
                    Setup new payment rules for your reservation{" "}
                  </div>
                  <div className='right-container'>
                    <div className='left-container-right'>
                      <Button
                        auth
                        text='New Payment Rule'
                        height='34px'
                        padding='0.5rem 1rem'
                        fontWeight={500}
                        onClick={() => {
                          setIsNewModalOpen(true);
                        }}
                      />
                      <div className='search-container'>
                        <SearchInput
                          value={value}
                          onChange={(e) => {
                            setValue(e);
                            if (e !== "") {
                              let updated = paymentRules.filter((rule: any) =>
                                rule.Name.toLowerCase().includes(
                                  e.toLowerCase()
                                )
                              );
                              setData(updated);
                              let updatedlump = lumpPaymentRules.filter(
                                (rule: any) =>
                                  rule.Name.toLowerCase().includes(
                                    e.toLowerCase()
                                  )
                              );
                              setLumpData(updatedlump);
                            } else {
                              setData(paymentRules);
                              setLumpData(lumpPaymentRules);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='payment-rules-table-view'>
                  <div className='payment-rules-table'>
                    <div className='table-header '>Per Cover</div>
                    <table id='bookings'>
                      <thead>
                        <tr>
                          <th>Payment Rule Name</th>
                          <th>Amount</th>
                          <th>Min Covers to Activate Rule</th>
                          <th>Auto Cancel Period </th>
                          {/* <th>Use Custom Fields</th> */}
                          <th>Status</th>
                          <th></th>{" "}
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((value: any) => {
                            return (
                              <tr style={{ cursor: "pointer" }}>
                                <td>{value.Name}</td>
                                <td>
                                  {value?.Rules?.PaymentAmountPerCover?.Text ??
                                    ""}
                                </td>
                                <td>
                                  {value?.Rules?.MinimumReservationCover ?? 0}
                                </td>
                                <td>
                                  {convertMinutesToString(
                                    value?.Rules?.AutoCancellation?.Duration ??
                                      0
                                  )}
                                </td>
                                {/* <td>{value.CustomFields}</td> */}
                                <td>
                                  {" "}
                                  {value.IsActive ? (
                                    <div className='active'>Active</div>
                                  ) : (
                                    <div className='inactive'>Inactive</div>
                                  )}
                                </td>
                                <td>
                                  <div className='more-icon'>
                                    <img
                                      alt=''
                                      src={more}
                                      onClick={() => {
                                        setRefId(value.PaymentRuleID);
                                        setOpenPopupIndex(
                                          openPopupIndex === value.ID
                                            ? ""
                                            : value.ID
                                        );
                                      }}
                                    />
                                    {morePopup(value)}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className='empty-container'>
                            <td colSpan={6}> No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='payment-rules-table'>
                    <div className='table-header '>Lump</div>
                    <table id='bookings'>
                      <thead>
                        <tr>
                          <th>Payment Rule Name</th>
                          <th>Amount</th>
                          <th>Min Covers to Activate Rule</th>
                          <th>Auto Cancel Period </th>
                          {/* <th>Use Custom Fields</th> */}
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {lumpData.length > 0 ? (
                          lumpData.map((value: any) => {
                            return (
                              <tr style={{ cursor: "pointer" }}>
                                <td>{value.Name}</td>
                                <td>
                                  {value?.Rules?.PaymentAmountPerCover?.Text ??
                                    ""}
                                </td>
                                <td>
                                  {value?.Rules?.MinimumReservationCover ?? 0}
                                </td>
                                <td>
                                  {convertMinutesToString(
                                    value?.Rules?.AutoCancellation?.Duration ??
                                      0
                                  )}
                                </td>
                                {/* <td>{value.CustomFields}</td> */}
                                <td>
                                  {value.IsActive ? (
                                    <div className='active'>Active</div>
                                  ) : (
                                    <div className='inactive'>Inactive</div>
                                  )}
                                </td>
                                <td>
                                  <div className='more-icon'>
                                    <img
                                      alt=''
                                      src={more}
                                      onClick={() => {
                                        setRefId(value.PaymentRuleID);
                                        setOpenPopupIndex(
                                          openPopupIndex === value.ID
                                            ? ""
                                            : value.ID
                                        );
                                      }}
                                    />
                                    {morePopup(value)}
                                  </div>
                                </td>{" "}
                              </tr>
                            );
                          })
                        ) : (
                          <tr className='empty-container'>
                            <td colSpan={6}> No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
      {newModalOpen && (
        <PaymentRulesModal
          onClose={() => {
            setIsNewModalOpen(false);
          }}
          setIsSuccess={setIsSuccess}
          paymentRules={[...paymentRules, ...lumpPaymentRules]}
        />
      )}
      <SuccessModal
        isOpen={isSuccess}
        onClose={() => {
          getData();
          setIsSuccess(false);
        }}
        onOk={() => {
          getData();
          setIsSuccess(false);
        }}
        header='Saved Successfully!'
        content='The Payment Rules have been saved successfully.'
      />
      <SuccessModal
        isOpen={isDeleteSuccess}
        onClose={() => {
          getData();
          setIsDeleteSuccess(false);
        }}
        onOk={() => {
          getData();
          setIsDeleteSuccess(false);
        }}
        header='Successfully Deleted!'
        content='Successfully Payment Rule deleted'
      />
      <DeleteModal
        isOpen={isDeleteOpen}
        onDelete={() => {
          setDeleteLoading(true);
          deletePaymentRule(refId)
            .then((res) => {
              if (res) {
                setIsDeleteOpen(false);
                setRefId("");

                setIsDeleteSuccess(true);
              } else {
                setDeleteLoading(false);
              }
            })
            .catch(() => {
              setDeleteLoading(false);
            });
        }}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        isLoading={deleteLoading}
        content='Are you sure that you want to delete this?'
      />
    </div>
  );
};
export default PaymentRules;
