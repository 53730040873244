import React, { useState } from "react";
import arrow from "../../../../assets/Shape.svg";
interface RoomTablesDropdownProps {
  value: any;
  selected: any;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
}
const RoomTablesDropdown: React.FC<RoomTablesDropdownProps> = ({
  value,
  selected,
  setSelected,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isValid = (asset: any) => {
    return selected.some((table: any) => table.ID === asset.ID);
  };
  return (
    <div>
      <div className={`floor-type ${isOpen ? "floor-type-selectd " : ""}`}>
        <div
          className='floor-type-details'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className='left-shift-view'>{value.DisplayName}</div>
          <div className='right-shift-view '>
            <div className='arrow-cont'>
              <img alt='' src={arrow} />
            </div>
          </div>
        </div>
        {isOpen ? (
          <div className='tables'>
            {value.Tables.length > 0 ? (
              value.Tables.filter((val: any) => val.IsOnline).map(
                (asset: any) => {
                  return (
                    <div
                      className={`table ${
                        isValid(asset) ? "table-selected " : ""
                      }`}
                      onClick={() => {
                        const updated = selected.some(
                          (table: any) => table.ID === asset.ID
                        )
                          ? selected.filter(
                              (table: any) => table.ID !== asset.ID
                            )
                          : [
                              ...selected,
                              {
                                Table: asset.TableName,
                                ID: asset.ID,
                                Room: value.DisplayName,
                                RoomID: value.FloorID,
                              },
                            ];

                        setSelected(updated);
                      }}
                    >
                      <div>
                        {asset.TableName}{" "}
                        <span className='cover-text'>{`(${asset.Covers.Maximum} Covers)`}</span>
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <div className='table'>No tables available</div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default RoomTablesDropdown;
